import React from "react";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";
import styles from "../../styles/photoList.module.css";
import useApiData from "./FetchDataFromApiProjects";

export default function ProjectList() {
  const { data, loading, error } = useApiData();
  console.log(data[1])

  if (loading) {
    return <p>Lade Daten...</p>;
  }

  if (error) {
    return <p>Es ist ein Fehler aufgetreten: {error.message}</p>;
  }

  return (
    <>
      <h1 className= {styles.heading}> Projekte</h1>
      <div className={styles.container}>
        <div className={styles.cardList}>
          {data.map((project) => (
            <div key={project.id}>
              <Link className={styles.link} to={`/projects/${project.id}`}>
                <Card className={styles.card}>
                  <p className={styles.cardListTitle}>{project.title}</p>
                  <Image
                    className={styles.cardImage}
                    src={`${project.preview_image}`}
                    alt={`Ticket ${project.id} Image`}
                    width={500}
                    height={500}
                  />
                </Card>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
