import React, { useState, useEffect } from "react";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import styles from "../../styles/photoGalleryDetail.module.css";

export default function ProjectGallery({ data }) {
  const [expanded, setExpanded] = React.useState(false);

  const [imageWidth, setImageWidth] = useState("800px");
  const [imageSrc, setImageSrc] = useState("");

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const renderImage = (imageSrc, imageId, imageWidth, imageText) => {
    if (imageSrc !== "") {
      return (
        <>
          <Image
            id={imageId}
            src={`/../${imageSrc}`}
            alt={`Ticket ${imageSrc} Image`}
            style={{ width: imageWidth, height: "auto" }}
          />
          <p className={styles.subheading}> {imageText}</p>
        </>
      );
    } else {
      return <p></p>;
    }
  };

  useEffect(() => {
    if (data.image_01.includes("wizard")) {
      setImageWidth("300px");
    } else {
      setImageWidth("800px");
    }
  }, [data.image_01]);

  return (
    <div className={styles.gallery}>
      <div className={styles.galleryContainer}>
        <p>
          {expanded && data.desc
            ? data.desc
            : data.desc && `${data.desc.slice(0, 700)}...`}
        </p>
        <Button
          variant="outline-light"
          onClick={toggleExpand}
          className={styles.button}
        >
          {expanded ? "Weniger anzeigen" : "Mehr anzeigen"}
        </Button>
      </div>
      <div className={styles.imageGallery}>
        {renderImage(
          data.image_01,
          styles.image_01,
          imageWidth,
          data.image_01_text
        )}
        {renderImage(
          data.image_02,
          styles.image_02,
          imageWidth,
          data.image_02_text
        )}
        {renderImage(
          data.image_03,
          styles.image_03,
          imageWidth,
          data.image_03_text
        )}
      </div>
    </div>
  );
}
