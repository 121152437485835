import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import styles from "../styles/footer.module.css";
import { Link } from "react-router-dom";

export default function Footer() {
  // const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    // Überprüfe, ob der Benutzer weit genug nach unten gescrollt ist
    const scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop;
    const threshold = 200; // Ändere dies je nach gewünschtem Schwellenwert

    // setIsVisible(scrollTop > threshold);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Fügt einen Scroll-Animationseffekt hinzu
    });
  };

  useEffect(() => {
    // Füge einen Event Listener für das Scrollen hinzu
    window.addEventListener("scroll", handleScroll);

    // Entferne den Event Listener, wenn die Komponente unmontiert wird
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={styles.footer}>
      <div className={styles.contact}>
        <h3>Portfolio | Marvin Kratzert</h3>
        <h3>marvin.kratzert@web.de</h3>
      </div>

      <Button
        onClick={scrollToTop}
        variant="outline-light"
        className="scroll-to-top-button"
        id={styles.backToTopBtn}
      >
        Nach oben
      </Button>

      <Link to="/aboutPage">
        <Button
          onClick={scrollToTop}
          variant="outline-light"
          className="scroll-to-top-button"
          id={styles.backToTopBtn}
        >
          Über diese Website
        </Button>
      </Link>

      <div className={styles.clock}></div>
    </div>
  );
}
