import styles from "../styles/aboutPage.module.css";
import Footer from "../Components/Footer";

export default function About() {
  return (
    <>
     
      <br />
      <h1 className={styles.heading}>Über dieses Projekt</h1>
      <div className={styles.container}>
        <div className={styles.content}>
          <p className={styles.aboutMe}>
            <br />
            <h2 className={styles.subHeading}>Eingesetzte Technologien</h2>
            <p>
              Mein Portfolio ist nicht nur eine digitale Visitenkarte, sondern
              auch ein technologisches Showcase, das auf einer stabilen und
              leistungsfähigen Infrastruktur basiert.
              <br />
              Hinter den Kulissen wurde ein Linux Server eingerichtet, auf dem
              ein Node Server betrieben wird. Dies ermöglicht eine zuverlässige
              Bereitstellung meiner Anwendung. Die gesamte Benutzeroberfläche
              meines Portfolios wurde mit React entwickelt, Durch die Verwendung
              von React konnte ich eine reaktionsschnelle und interaktive
              Website gestalten, die sich nahtlos an verschiedene
              Bildschirmgrößen anpasst.
              <br />
              Die Integration einer REST-API zu meinem Server ermöglicht eine
              effiziente Datenkommunikation zwischen der Benutzeroberfläche und
              dem Backend. Dadurch werden dynamische Inhalte geladen, was eine
              personalisierte und ansprechende Erfahrung für die Besucher meines
              Portfolios gewährleistet. Dieses Portfolio repräsentiert nicht nur
              meine Arbeit, sondern auch meine technologischen Fähigkeiten und
              meine Herangehensweise an die Webentwicklung. Es ist das Ergebnis
              sorgfältiger Planung, kreativer Umsetzung und der Nutzung
              modernster Technologien, um eine beeindruckende Online-Präsenz zu
              schaffen.
            </p>
          </p>
        </div>
      </div>
      <Footer className={styles.footer} />
    </>
  );
}
