export const fetchDataByIdProjects = async (id) => {
  try {
    // const response = await fetch(`http://localhost:4000/projects`);
    const response = await fetch(`http://85.214.197.191:4000/projects`);
    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }

    const jsonData = await response.json();
    const item = jsonData.find((item) => item.id === id);
    console.log("fetchFunktion: " + item.id);

    return item;
  } catch (error) {
    console.error("Error fetching or processing data 123:", error);
    throw error; // Du kannst den Fehler hier werfen, um ihn in der aufrufenden Komponente zu behandeln
  }
};
