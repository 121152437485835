import React from "react";
import {
  HashRouter as Router,
  Route,
  Link,
  Routes,
  Outlet,
} from "react-router-dom";
import styles from "../styles/navigation.module.css";
import Home from "../pages/Home";
import Projects from "../pages/Projects";
import Photos from "../pages/Photos";
import DetailPageProjects from "../pages/DetailPageProject";
import DetailPagePhotos from "../pages/DetailPage";
import NotFound from "../pages/NotFoundPage";
import Clock from "./Clock";
import About from "../pages/About";
import SideBar from "./SideBar";
import AboutPage from "../pages/AboutPage"

export default function Navigation() {
  return (
    <div>
      <Router>
        <nav className={styles.navigation}>
          <ul>
            <p>
              <Clock />
            </p>
            <li>
              <Link className={styles.navigationHide} to="/">
                Home
              </Link>
            </li>
            <li>
              <Link className={styles.navigationHide} to="/projects">
                Projekte
              </Link>
            </li>
            <li>
              <Link className={styles.navigationHide} to="/photography">
                Fotografie
              </Link>
            </li>
            <li>
              <Link className={styles.navigationHide} to="/about">
                About
              </Link>
            </li>
            <li>
              <SideBar className ={styles.SideBar}/>
            </li>
          </ul>
        </nav>

        <Outlet />

        <Routes className={styles.navigationLinks}>
          <Route path="/" element={<Home />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/photography" element={<Photos />} />
          <Route path="/photography/:id" element={<DetailPagePhotos />} />
          <Route path="/projects/:id" element={<DetailPageProjects />} />
          <Route path="/aboutPage/" element={<AboutPage />} />
          <Route path="/about/" element={<About />} />
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </Router>
    </div>
  );
}
