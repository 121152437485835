import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styles from "../styles/photoDetail.module.css";
import Footer from "../Components/Footer";
import ProjectGallery from "../Components/Projects/ProjectGalleryDetail";
import { fetchDataByIdProjects } from "../Components/Projects/FetchDatabyIdProjects";

import ImageCollage from "../Components/ImageCollage";
import LinkToRandomProject from "../Components/Projects/LinkToRandomProject";

function DetailPageProject() {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [isAndroid, setIsAndroid] = useState(false);

  const [tags, setTags] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const specificItem = await fetchDataByIdProjects(id);
        setData(specificItem);
        setTags(specificItem.tags.split(","));

        if (specificItem.heroImage === "images/android.gif") {
          setIsAndroid(true);
        }
      } catch (error) {
        // Hier kannst du den Fehler entsprechend behandeln
        console.error("Error in fetchData:", error);
      }
    };

    fetchData();
  }, [id]);

  if (!data) {
    return <p>Loading...</p>;
  }

  return (
    <main>
      <br />
      <nav className={styles.navText}>
        <h1 className={styles.heading}> {data.title}</h1>

        <div>
          <img
            className={isAndroid ? styles.android : styles.isNotAndroid}
            src={data.heroImage}
            alt={data.title}
          />
          <p>
            {tags.map((string, index) => (
              <span className={styles.tags} key={index}>
                {string}
              </span>
            ))}
          </p>

          {/* <ImageCollage id={id} /> */}
        </div>
      </nav>
      <div className={styles.cardGroup}>
        <div className={styles.card}>
          {/* <PreView data={data} /> */}
          <ProjectGallery data={data} />
        </div>
      </div>

      <div>
        <LinkToRandomProject />
      </div>

      <Footer />
    </main>
  );
}

export default DetailPageProject;
