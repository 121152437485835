import styles from "../styles/sidebar.module.css";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import CloseButton from "react-bootstrap/CloseButton";

import Button from "react-bootstrap/Button";

export default function SideBar() {
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeSideBar = () => {
    setIsOpen(false);
  };
  return (
    <>
      <Button variant="light" id = {styles.burgerButton}>
        <div onClick={toggleMenu}>
          <div className={styles.line}></div>
          <div className={styles.line}></div>
          <div className={styles.line}></div>
        </div>
      </Button>

      {isOpen ? (
        <div className={styles.overlay}>
          <CloseButton id={styles.closeButton} onClick={closeSideBar} />

          <div>
            <li>
              <ul>
                <Link
                  className={styles.listItems}
                  onClick={closeSideBar}
                  to={`/`}
                >
                  <a>home</a>
                </Link>
              </ul>

              <ul>
                <Link
                  className={styles.listItems}
                  onClick={closeSideBar}
                  to={`/projects`}
                >
                  <a>projects</a>
                </Link>
              </ul>

              <ul>
                <Link
                  className={styles.listItems}
                  onClick={closeSideBar}
                  to={`/photography`}
                >
                  <a>Photography</a>
                </Link>
              </ul>

              <ul>
                <Link
                  className={styles.listItems}
                  onClick={closeSideBar}
                  to={`/about`}
                >
                  <a>about</a>
                </Link>
              </ul>
            </li>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
}
