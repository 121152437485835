import "./App.css";
import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import Navigation from "./Components/Navigation";

function App() {
  const [show, setShow] = useState(false);

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    // Nach 5 Sekunden das Modal öffnen
    const timeout = setTimeout(() => {
      setShowModal(true);
    }, 1000);

    // Aufräumen, um Memory-Leaks zu vermeiden
    return () => clearTimeout(timeout);
  }, []);

  const handleConfirm = () => {
    setShowModal(false);
    // Hier kannst du weitere Aktionen nach der Bestätigung durchführen
  };
  return (
    <div className="App">
      <header className="App-header">
        <Navigation />
        <Modal
          backdrop="static"
          show={showModal}
          onHide={() => setShowModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Website ist in Bearbeitung</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Diese Website und Linux-Server befinden sich aktuell noch in
              Bearbeitung
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleConfirm}>
              Bestätigen
            </Button>
          </Modal.Footer>
        </Modal>
      </header>
    </div>
  );
}

export default App;
