import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styles from "../styles/photoDetail.module.css";
import Footer from "../Components/Footer";
import PhotoGallery from "../Components/Photography/PhotoGalleryDetail";
import { fetchDataById } from "../Components/Photography/FetchDatabyId";
import ImageCollage from "../Components/ImageCollage";
import LinkToRandomPhoto from "../Components/Photography/LinkToRandomPhoto";

function DetailPage() {
  const { id } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const specificItem = await fetchDataById(id);
        setData(specificItem);
        console.log("fetchData:", specificItem);
      } catch (error) {
        // Hier kannst du den Fehler entsprechend behandeln
        console.error("Error in fetchData 123455:", error);
      }
    };

    fetchData();
  }, [id]);

  if (!data) {
    return <p>Loading...</p>;
  }

  return (
    <main>
      <br />
      {/* <LikeButton id = {id}/> */}
      <nav className={styles.navText}>
        <h1> {data.title}</h1>
        <div>
        <img
            className={styles.isNotAndroid}
            src={data.heroImage}
            alt={data.title}
          />
          {/* <ImageCollage id={id} /> */}
        </div>
      </nav>
      <div className={styles.cardGroup}>
        <div className={styles.card}>
          {/* <PreView data={data} /> */}
          <PhotoGallery data={data} />
        </div>
      </div>

      <div>
        <LinkToRandomPhoto />
      </div>

      <Footer />
    </main>
  );
}

export default DetailPage;
