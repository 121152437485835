import React from "react";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";
import styles from "../../styles/photoList.module.css";
import useApiData from "./FetchDataFromApi";

export default function PhotoList() {
  const { data, loading, error } = useApiData();

  if (loading) {
    return <p>Lade Daten...</p>;
  }

  if (error) {
    return <p>Es ist ein Fehler aufgetreten: {error.message}</p>;
  }

  return (
    <>
      <h1 className= {styles.heading}> Fotografie</h1>
      <div className={styles.container}>
        <div className={styles.cardList}>
          {data.map((photo) => (
            <div key={photo.id}>
              <Link className={styles.link} to={`/photography/${photo.id}`}>
                <Card className={styles.card}>
                  <p className={styles.cardListTitle}>{photo.title}</p>
                  <Image
                    className={styles.cardImage}
                    src={`/${photo.preview_image}`}
                    alt={`Ticket ${photo.id} Image`}
                    width={500}
                    height={500}
                  />
                </Card>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
