import styles from "../styles/about.module.css";
import Footer from "../Components/Footer";

import peaceImg from "../_data/peace.png";
export default function About() {
  return (
    <>
      <br />
      <h1 className={styles.heading}>About</h1>
      <div className={styles.container}>
        <div className={styles.content}>
          <img className={styles.peaceImage} src={peaceImg}></img>
          <p className={styles.aboutMe}>
            <br />
            <h2 className={styles.subHeading}>Berufliche Ziele</h2>
            <p>
              In meiner beruflichen Reise strebe ich danach, ein Experte im
              Bereich der Software-Entwicklung zu werden. Mein Fokus liegt
              darauf, meine Fähigkeiten in verschiedenen Schlüsselbereichen
              weiter zu vertiefen.
              <br />
              <br />
              <bold className={styles.boldText}>Software-Entwicklung: </bold>
              Mein Fokus liegt nicht nur auf der Beherrschung gängiger
              Standards, sondern auch auf der Entwicklung innovativer Lösungen
              für komplexe Herausforderungen. Ich strebe danach, meine kreativen
              Fähigkeiten in der Entwicklung von Software einzusetzen und stets
              auf dem neuesten Stand der Technik zu bleiben. Mein Ziel ist es,
              nicht nur bewährte Methoden anzuwenden, sondern auch neue Wege zu
              erkunden und dabei die Möglichkeiten modernster Technologien voll
              auszuschöpfen.
              <br />
              <br />
              <bold className={styles.boldText}>Cloud-Technologien:</bold> Die
              Welt der Software entwickelt sich ständig weiter, und ich
              beabsichtige, meine Kenntnisse im Bereich Server- und
              Cloud-Technologien zu erweitern. Dies umfasst die effiziente
              Verwaltung von Servern, die Implementierung von Cloud-Lösungen und
              die Optimierung von Anwendungen für eine skalierbare und
              zuverlässige Leistung.
              <br />
              <br />
              <bold className={styles.boldText}>Sicherheit:</bold> Mit dem
              zunehmenden Fokus auf Datenschutz und Sicherheit ist es mein Ziel,
              mich intensiver mit Sicherheitsaspekten in der
              Software-Entwicklung auseinanderzusetzen. Dies beinhaltet die
              Umsetzung bewährter Sicherheitspraktiken, die Identifizierung von
              Schwachstellen und die Implementierung von robusten
              Sicherheitsmaßnahmen. Diese Ziele spornen mich an, kontinuierlich
              zu lernen, mich herauszufordern und in der sich ständig
              verändernden Welt der Software-Entwicklung relevant zu bleiben.
              Ich freue mich darauf, meine Fähigkeiten auszubauen und innovative
              Lösungen zu schaffen, die einen positiven Einfluss auf die
              digitale Landschaft haben.
              <br />
              <br />
              <bold className={styles.boldText}>Backend-Entwicklung:</bold>{" "}
              Neben meinen Bestrebungen, mich in der Software-Entwicklung,
              Cloud-Technologien und Sicherheit zu vertiefen, setze ich mir auch
              das Ziel, ein Experte in der Backend-Entwicklung zu werden. Das
              beinhaltet die Gestaltung und Optimierung von Server-Technologien,
              die effiziente Verarbeitung von Daten, die Implementierung von
              Schnittstellen für reibungslose Kommunikation zwischen Frontend
              und Backend sowie die Gewährleistung der Gesamtleistung und
              Zuverlässigkeit von Software-Anwendungen.
            </p>
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
}
