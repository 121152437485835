import React from "react";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import styles from "../../styles/photoGalleryDetail.module.css";

export default function PhotoGallery({ data }) {
  const [expanded, setExpanded] = React.useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const renderImage = (imageSrc, imageId) => {
    if (imageSrc !== "") {
      console.log("imageSrc :", imageSrc);
      return (
        <Image
          id={imageId}
          src={`/../${imageSrc}`}
          alt={`Ticket ${imageSrc} Image`}
        />
      );
    } else {
      return <p></p>;
    }
  };

  return (
    <div className={styles.gallery}>
      <div className={styles.galleryContainer}>
        <p>
          {expanded && data.desc
            ? data.desc
            : data.desc && `${data.desc.slice(0, 100)}...`}
        </p>
        <Button
          variant="outline-light"
          onClick={toggleExpand}
          className={styles.button}
        >
          {expanded ? "Weniger anzeigen" : "Mehr anzeigen"}
        </Button>
      </div>
      <div className={styles.imageGallery}>
        {renderImage(data.image_01, styles.image_01)}
        {renderImage(data.image_02, styles.image_02)}
        {renderImage(data.image_03, styles.image_03)}
      </div>
    </div>
  );
}
