import Footer from "../Components/Footer";
import styles from "../styles/photoList.module.css";
import PhotoList from "../Components/Photography/PhotoList";

export default function Photos() {
  return (
    <>
      <br />
      {/* <div className={styles.photoPage}>Fotos</div> */}

      <div>
        <PhotoList />
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
}
