import React from "react";
import profilImage from "../_data/_DSC99012.png";
import styles from "../styles/home.module.css";
import Footer from "../Components/Footer";

export default function Home() {
  const technologies = [
    "JavaScript",
    "Java",
    "Kotlin",
    "C#",
    "Apex Oracle",
    "React",
    "Vue",
    "HTML",
    "CSS",
    "REST APIs",
    "DevOps",
    "SCRUM",
    "Azure",
    "SQL",
    "Git",
    "UX-Design",
  ];

  const techElements = technologies.map((data, index) => (
    <a className={styles.techElements} key={index}>
      {data}
    </a>
  ));

  return (
    <>
      <main className={styles.home}>
        <br />
        <h1 className={styles.heading}>Willkommen auf meinem Portfolio</h1>
        <div className={styles.containerHome}>
          <div className={styles.contentHome}>
            <img
              className={styles.imageHome}
              src={profilImage}
              alt="Marvin not here"
            />
            <div className={styles.flexContainer}>
              <p className={styles.aboutMe}>
                <br />
                Hallo, ich bin Marvin, ein 26-jähriger Mediendesign-Absolvent
                mit einem Bachelor of Science-Abschluss von der Hochschule
                Ravensburg Weingarten.
                <br />
                Meine Leidenschaft gilt der Softwareentwicklung, wo ich meine
                Kreativität und technischen Fähigkeiten vereinen kann. Während
                meines Studiums habe ich fundierte Kenntnisse im Bereich
                Mediendesign erworben, wodurch ich in der Lage bin, ansprechende
                und funktionale digitale Lösungen zu gestalten.
                <br />
                Von der Konzeption bis zur Umsetzung arbeite ich gerne daran,
                nutzerfreundliche und ästhetisch ansprechende Anwendungen zu
                entwickeln. In meiner Freizeit tausche ich den Bildschirm gegen
                das Fitnessstudio und widme mich dem Krafttraining. Dies ist
                nicht nur eine sportliche Betätigung für mich, sondern auch ein
                Hobby, das mir hilft, einen Ausgleich zu meiner technischen
                Arbeit zu finden.
                <br />
                Abseits von Codezeilen und Hanteln bin ich auch ein begeisterter
                Hobbyfotograf. Das Festhalten von Momenten und das Spielen mit
                visuellen Elementen faszinieren mich ebenso wie die Welt des
                Codes. Die Kombination meiner Leidenschaften für Design,
                Entwicklung und Fotografie eröffnet mir vielfältige
                Möglichkeiten, kreative Projekte zu realisieren.
                <br />
                Wenn du mehr über mich erfahren möchtest oder an einer
                Zusammenarbeit interessiert bist, stehe ich gerne zur Verfügung.
                Lass uns gemeinsam etwas Großartiges schaffen!
              </p>
            </div>
            <div className={styles.container}>
              <p className={styles.techElementsContainer}>
                <h2 id={styles.headingTech}>Technologien</h2>
                <div>{techElements}</div>
              </p>
            </div>
          </div>
        </div>
      </main>
      <Footer className={styles.footer} />
    </>
  );
}
