const fetchDataFromApi = async () => {
  try {
    const apiUrl = "http://85.214.197.191:4000/photos";
    // const apiUrl = "http://localhost:4000/photos";
    const response = await fetch(apiUrl);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error fetching data:", error.message);
    throw error;
  }
};

export { fetchDataFromApi };
