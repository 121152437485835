import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import styles from "../../styles/randomProjects.module.css";
import { Link } from "react-router-dom";
import useApiData from "./FetchDataFromApiProjects";

export default function LinkToRandomProject() {
  const { data, loading, error } = useApiData();
  const [randomProjects, setRandomProjects] = useState([]);

  const handleScroll = () => {
    // Überprüfe, ob der Benutzer weit genug nach unten gescrollt ist
    const scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop;
    const threshold = 200; // Ändere dies je nach gewünschtem Schwellenwert

    // setIsVisible(scrollTop > threshold);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Fügt einen Scroll-Animationseffekt hinzu
    });
  };

  useEffect(() => {
    if (data && data.length > 0) {
      const randomProjects = getRandomElements(data, 3);
      setRandomProjects(randomProjects);
    }

    // Füge einen Event Listener für das Scrollen hinzu
    window.addEventListener("scroll", handleScroll);

    // Entferne den Event Listener, wenn die Komponente unmontiert wird
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [data]);

  function getRandomElements(arr, num) {
    if (!arr) {
      return [];
    }

    const shuffled = arr.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, num);
  }

  const loadRandomProjects = () => {
    if (data && data.length > 0) {
      const randomProjects = getRandomElements(data, 3);
      setRandomProjects(randomProjects);
    }
  };

  if (loading) {
    return <p>Lade Daten...</p>;
  }

  if (error) {
    return <p>Es ist ein Fehler aufgetreten: {error.message}</p>;
  }

  return (
    <>
      <div className={styles.container}>
        <h1 id={styles.furtherProjectsText}>Weitere Projekte </h1>
        <Button className={styles.button} onClick={loadRandomProjects}>
          Neu laden
        </Button>
        <div className={styles.projectContainer}>
          {randomProjects.map((project) => (
            <div key={project.id} className={styles.project}>
             <p>{project.title && project.title.length > 20 ? `${project.title.slice(0, 20)}...` : project.title}</p>
              <div className={styles.imageContainer}>
                <img
                  className={styles.image}
                  src={project.preview_image}
                  alt={project.title}
                  key={project.id}
                ></img>
              </div>

              <Link to={`/projects/${project.id}`}>
                <Button className={styles.nextProjectBtn} onClick={scrollToTop}>
                  Gehe zu
                </Button>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
